import { Input } from "@ns/client-ui";
import { RefObject } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { Controller, useFormContext } from "react-hook-form";

import sendMessage from "/svg/send-message-white.svg";
import exclamationSvg from "/svg/exclamation.svg";

import CloseBtn from "./svg-components/close-btn.tsx";
import { GalleryIcon } from "./svg-components/gallery-icon.tsx";

const InputMessage = ({
  name,
  placeholder,
  onClick,
  getInputProps,
  fileInputRef,
  chatImageUrl,
  setChatImageUrl,
}: {
  name: string;
  placeholder: string;
  onClick: () => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  fileInputRef: RefObject<HTMLInputElement>;
  chatImageUrl?: string | null;
  setChatImageUrl: (imageUrl: string | null) => void;
}) => {
  const { control } = useFormContext();

  const onClickHandler = () => {
    onClick();
  };

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className="flex flex-1 gap-4 w-full relative h-[80px]">
          {chatImageUrl && (
            <div
              className={
                "absolute w-full pt-2 pb-2 top-[-85%] -translate-y-1/2 bg-transparent"
              }
            >
              <img
                className={"w-[4rem] h-[4rem]"}
                src={chatImageUrl}
                alt={"chat-image"}
              ></img>
              <div className="absolute top-[0.5rem] left-[4.5rem]">
                <CloseBtn
                  className={"h-[0.5rem] w-[0.5rem]"}
                  onClick={() => setChatImageUrl(null)}
                />
              </div>
            </div>
          )}
          <div className={"w-full relative h-[40px]"}>
            <Input
              type="text"
              name={name}
              className={`bg-transparent border text-white placeholder-gray-400 focus:outline-none flex-1`}
              placeholder={placeholder}
              value={field.value ?? ""}
              onChange={field.onChange}
              disabled={false}
            />
            <input {...getInputProps()} ref={fileInputRef} />
            <GalleryIcon
              onClick={() =>
                !chatImageUrl ? fileInputRef?.current?.click() : {}
              }
              className={
                "absolute right-[3rem] h-6 w-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
              }
            />
            <img
              src={sendMessage}
              onClick={onClickHandler}
              alt="send"
              className="absolute top-1/2 right-[10px] transform -translate-x-1/2 -translate-y-1/2 cursor-pointer "
            />
          </div>
          {error?.message && (
            <div className="absolute top-[40px] flex items-baseline justify-start gap-[3px]">
              <img src={exclamationSvg} alt="exclamation" />
              <span className="text-red-600 text-[12px]">{error.message}</span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default InputMessage;
