import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

type UseChatPaginationProps = {
  queryKey: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryFn: (offset: number, limit: number) => any;
  onError?: ((err: unknown) => void) | undefined;
  enabled: boolean;
  limit?: number;
  retry?: boolean;
};

type Page<T> = {
  items: T[];
  count: number;
  totalPages: number;
  page: number;
};

export const useChatPagination = <T>({
  queryFn,
  enabled,
  queryKey,
  limit = 12,
  onError,
  retry = true,
}: UseChatPaginationProps) => {
  const [fetchingPoints, setFetchingPoints] = useState<boolean[]>([]);
  const [isFetchingTop, setIsFetchingTop] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  const {
    fetchNextPage,
    remove,
    refetch,
    isFetchingNextPage,
    isLoading,
    isError,
    data,
    isRefetching,
  } = useInfiniteQuery<Page<T>, unknown, Page<T>, string[]>({
    queryKey,
    queryFn: ({ pageParam = 1 }) => {
      return queryFn((pageParam - 1) * limit, limit);
    },
    getNextPageParam: (lastPage) => {
      // @ts-ignore
      const nextPage = lastPage[queryKey].page + 1;
      // @ts-ignore
      return nextPage <= lastPage[queryKey].totalPages ? nextPage : undefined;
    },
    // direction: 'forward',
    enabled,
    onError,
    retry: retry,
  });

  useEffect(() => {
    if (entry) {
      setFetchingPoints((prev) => [...prev, false]);
    }
  }, [entry]);

  useEffect(() => {
    const handleScroll = () => {
      const chatContainer = containerRef.current;
      if (
        inView &&
        chatContainer &&
        chatContainer.scrollTop === 0 &&
        !isFetchingTop &&
        fetchingPoints.length &&
        // @ts-ignore
        !fetchingPoints.at(-1)
      ) {
        const previousScrollTop = chatContainer.scrollTop;

        setIsFetchingTop(true);
        setFetchingPoints((prev) => [...prev.slice(0, -1), true]);
        fetchNextPage().finally(() => {
          setIsFetchingTop(false);
          if (chatContainer) {
            chatContainer.scrollTop =
              chatContainer.scrollHeight - previousScrollTop;
          }
        });
      }
    };

    const chatContainer = containerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [inView, fetchingPoints, fetchNextPage, isFetchingTop]);

  useEffect(() => () => remove(), [remove]);

  const preparedData = useMemo(
    () => ({
      items:
        data?.pages?.reduce((_: T[], page) => {
          // @ts-ignore
          return page[queryKey].items;
        }, []) || [],
      // @ts-ignore
      totalItems: data?.pages[data.pages.length - 1][queryKey].count || 0,
      // @ts-ignore
      page: data?.pages[data.pages.length - 1][queryKey].page || 0,
    }),
    [queryKey, data?.pages],
  );

  return {
    data: preparedData.items,
    totalItems: preparedData.totalItems,
    page: preparedData.page,
    isLoading,
    isError,
    limit,
    isFetchingNextPage,
    containerRef,
    fetchNextPage,
    refetch,
    isRefetching,
    lastItemRef: ref,
  };
};
