import { WhoAmIClientResponse } from "@ns/api";
import { Toaster, useToast } from "@ns/styles";
import { ToastAction } from "@ns/styles/src/components/ui/toast.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import useWebSocket from "react-use-websocket";

import { api } from "../../api/client-api";
import {
  getCodeVerified,
  getSessionToken,
  setCodeVerified,
} from "../../api/token-helper.ts";
import { EventDetail } from "../../constants/event-detail.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { removeLocalstorageItem } from "../../helpers/localstorage.ts";
import { chatMessageSchema } from "../../schemas/form/send-chat-message-schema.ts";
import { sendMessageSchema } from "../../schemas/form/send-message-schema.ts";
import { uploadImageSchema } from "../../schemas/form/upload-image-schema.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import { useDialogContext } from "../../store/dialog-store.ts";
import useUserStore from "../../store/user-store";
import AddReferralDialog from "../dialogs/add-referral-dialog.tsx";
import ChangeLanguageDialog from "../dialogs/change-language-dialog.tsx";
import ChangePasswordDialog from "../dialogs/change-password-dialog.tsx";
import ChangePinDialog from "../dialogs/change-pin-dialog.tsx";
import CompleteSignUpDialog from "../dialogs/complete-sign-up.tsx";
import LockScreenDialog from "../dialogs/lock-screen-dialog.tsx";
import NewPinDialog from "../dialogs/new-pin-dialog.tsx";
import PinChangeSuccessDialog from "../dialogs/pin-change-success-dialog.tsx";
import PinSetSuccessDialog from "../dialogs/pin-set-success-dialog.tsx";
import UploadImageDialog from "../dialogs/upload-image-dialog.tsx";
import WinnerComment from "../dialogs/winner-comment.tsx";
import ControlledForm from "../form/controlled-form.tsx";
import Header from "../header";
import NotificationPopup from "../notification-popup.tsx";
import SideMenu from "../side-menu.tsx";
import StealdealLoader from "../stealdeal-loader.tsx";
import { BtcClosed } from "../svg-components/btc-closed.tsx";
import BtcToast from "../svg-components/btc-toast.tsx";
import { EthClosed } from "../svg-components/eth-closed.tsx";
import EthToast from "../svg-components/eth-toast.tsx";
import Chat from "../ui/chat.tsx";
import MobileMenu from "../ui/mobile-menu.tsx";

import giftSvg from "/svg/gift.svg";
import myReferral from "/svg/reminder-icon.svg";

type WebSocketMessage = {
  data: {
    payload: {
      userId: string;
    };
  };
  event: string;
};

const MainLayout = () => {
  const { setUser, user, setLanguageId, setHasUnreadNotifications } =
    useUserStore();
  const { setIsPinModalOpen } = useAuthContext();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isIncompleteOpen, setIsIncompleteOpen] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const {
    isAddReferralDialogOpen,
    isLanguageDialogOpen,
    isUploadImageDialogOpen,
    isChangePasswordDialogOpen,
    isLockScreenDialogOpen,
    isNewPinDialogOpen,
    isSuccessSetDialogOpen,
    isSuccessDialogOpen,
    isChangePinDialogOpen,
    setIsLanguageDialogOpen,
    setIsAddReferralDialogOpen,
    setIsUploadImageDialogOpen,
    setIsChangePasswordDialogOpen,
    setIsSuccessDialogOpen,
    setIsLockScreenDialogOpen,
    setIsChangePinDialogOpen,
    setIsSuccessSetDialogOpen,
    setIsNewPinDialogOpen,
  } = useDialogContext();

  const toggleClaimDialog = (): void => {
    setIsOpen((isOpen) => !isOpen);
  };

  const toggleIsIncompleteDialog = useCallback(() => {
    setIsIncompleteOpen((isOpen) => !isOpen);
  }, [setIsIncompleteOpen]);

  const { data: checkUnclaimed } = useQuery({
    queryKey: ["checkUnclaimed"],
    queryFn: () => api.winners.checkUnclaimedDeals(),
    enabled: !!user?.id,
  });

  const { toast } = useToast();

  useEffect(() => {
    if (
      (checkUnclaimed?.hasUnclaimedWinnerDeal ||
        checkUnclaimed?.hasUnclaimedReferralDeal) &&
      (getCodeVerified() === "true" || !getCodeVerified())
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [setIsOpen, checkUnclaimed]);

  const { isLoading: isLoadingUser } = useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (data: WhoAmIClientResponse) => {
      setUser(data);
    },
    onError: () => {
      removeLocalstorageItem("betaModal");
      setUser(null);
    },
    retry: false,
  });

  const { refetch: refetchPinCode } = useQuery({
    queryKey: ["checkPinCode"],
    queryFn: () => api.pinCode.getPinCode(),
    enabled: !!getSessionToken(),
  });

  useEffect(() => {
    let codeVerified = getCodeVerified() === "true";

    if (user?.id) {
      if (getCodeVerified() === undefined) {
        refetchPinCode().then((response) => {
          if (response?.data?.isActive) {
            setCodeVerified(false);
            codeVerified = false;
            setIsPinModalOpen(true);
            return;
          }
        });
      }
      setIsPinModalOpen(
        getCodeVerified() !== undefined ? !codeVerified : false
      );
    }
  }, [user, setIsPinModalOpen, refetchPinCode]);

  useEffect(() => {
    if (!!user && !user?.completed) {
      toggleIsIncompleteDialog();
    }
  }, [user, toggleIsIncompleteDialog]);

  const { data: languages, isLoading: languagesLoading } = useQuery({
    queryFn: () => api.resources.getLanguages(),
    queryKey: ["languages"],
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (languages?.items) {
      if (user?.languageId) {
        const selected = languages?.items?.find(
          (language) => language.id === user.languageId
        );
        setLanguageId(user.languageId);

        if (selected) {
          i18n.changeLanguage(selected.code);
        }
      } else {
        const english = languages?.items?.find(
          (language) => language.code === "en"
        );
        setLanguageId(english?.id ?? "");
      }
    }
  }, [setLanguageId, languages?.items, user?.languageId, i18n]);

  const { isLoading: roundsLoading } = useQuery({
    queryFn: () => api.round.getRounds("LATEST"),
    queryKey: ["rounds"],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { isLoading: currenciesLoading } = useQuery({
    queryKey: ["currency"],
    queryFn: () => api.resources.getCurrencies(),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const params = user?.id
    ? {
        userId: user?.id ?? "",
        userType: "endUser",
      }
    : undefined;

  const { lastJsonMessage } = useWebSocket(environmentVariables.websocketUrl, {
    onOpen: () => console.log("opened"),
    onClose: () => console.log("closed"),
    queryParams: params,
    heartbeat: {
      message: "ping",
      returnMessage: "pong",
      timeout: 120000,
      interval: 60000,
    },
  });

  const queryClient = useQueryClient();
  const handleRoundFinished = () => {
    queryClient.invalidateQueries({
      queryKey: ["rounds"],
    });
    queryClient.invalidateQueries({
      queryKey: ["ALL"],
    });
    queryClient.invalidateQueries({
      queryKey: ["allRounds"],
    });
    queryClient.invalidateQueries({
      queryKey: ["winningBids"],
    });
    queryClient.invalidateQueries({
      queryKey: ["WINNING"],
    });
    queryClient.invalidateQueries({
      queryKey: ["ACTIVE"],
    });
    queryClient.invalidateQueries({
      queryKey: ["winners"],
    });
    queryClient.invalidateQueries({
      queryKey: ["checkUnclaimed"],
    });
    queryClient.invalidateQueries({
      queryKey: ["analytics"],
    });
    queryClient.invalidateQueries({
      queryKey: ["activeOrders"],
    });
  };

  const handleRoundStarted = () => {
    queryClient.invalidateQueries({
      queryKey: ["rounds"],
    });
    queryClient.invalidateQueries({
      queryKey: ["allRounds"],
    });
    queryClient.invalidateQueries({
      queryKey: ["activeOrders"],
    });
  };

  useEffect(() => {
    switch ((lastJsonMessage as WebSocketMessage)?.event) {
      case EventDetail.BIDS:
        queryClient.invalidateQueries({
          queryKey: ["ALL"],
        });
        queryClient.invalidateQueries({
          queryKey: ["activeOrders"],
        });
        queryClient.invalidateQueries({
          queryKey: ["ALL_CONFIRMED"],
        });
        queryClient.invalidateQueries({
          queryKey: ["analytics"],
        });
        break;
      case EventDetail.ROUND_UPDATE_BTC:
        queryClient.invalidateQueries({
          queryKey: ["rounds"],
        });
        queryClient.invalidateQueries({
          queryKey: ["allRounds"],
        });
        break;
      case EventDetail.ROUND_UPDATE_ETH:
        queryClient.invalidateQueries({
          queryKey: ["rounds"],
        });
        queryClient.invalidateQueries({
          queryKey: ["allRounds"],
        });
        break;
      case EventDetail.ROUND_STARTED_BTC:
        handleRoundStarted();
        toast({
          title: t("toast.newBtcRound"),
          description: t("toast.opportunity"),
          action: (
            <ToastAction
              onClick={() => navigate("/")}
              className={"bg-[#D9D9D933]"}
              asChild
              altText="Undo action"
            >
              <div>{t("common.placeBid")}</div>
            </ToastAction>
          ),
          variant: "info",
          icon: <BtcToast />,
        });
        break;
      case EventDetail.ROUND_STARTED_ETH:
        handleRoundStarted();
        toast({
          title: t("toast.newEthRound"),
          description: t("toast.opportunity"),
          action: (
            <ToastAction
              onClick={() => navigate("/")}
              className={"bg-[#D9D9D933]"}
              asChild
              altText="Undo action"
            >
              <div>{t("common.placeBid")}</div>
            </ToastAction>
          ),
          variant: "info",
          icon: <EthToast />,
        });
        break;
      case EventDetail.ROUND_FINISHED_BTC:
        handleRoundFinished();
        toast({
          title: t("toast.btcFinished"),
          description: t("toast.checkWinnersList"),
          action: (
            <ToastAction
              onClick={() => navigate("/winners")}
              className={"bg-[#D9D9D933]"}
              asChild
              altText="Undo action"
            >
              <div>{t("toast.seeWinner")}</div>
            </ToastAction>
          ),
          variant: "info",
          icon: <BtcClosed />,
        });
        break;
      case EventDetail.ROUND_FINISHED_ETH:
        handleRoundFinished();
        toast({
          title: t("toast.ethFinished"),
          description: t("toast.checkWinnersList"),
          action: (
            <ToastAction
              onClick={() => navigate("/winners")}
              className={"bg-[#D9D9D933]"}
              asChild
              altText="Undo action"
            >
              <div>{t("toast.seeWinner")}</div>
            </ToastAction>
          ),
          variant: "info",
          icon: <EthClosed />,
        });
        break;
      case EventDetail.NOTIFICATIONS:
        queryClient.invalidateQueries({
          queryKey: ["getNotificationsByUserId"],
        });
        queryClient.invalidateQueries({
          queryKey: ["hasUnreadNotifications"],
        });
        setHasUnreadNotifications(true);
        break;
      case EventDetail.BANNER:
        queryClient.invalidateQueries({ queryKey: ["banner"] });
        break;
      case EventDetail.USER_UPDATE:
        queryClient.invalidateQueries({ queryKey: ["auth"] });
        break;
      case EventDetail.WINNER:
        queryClient.invalidateQueries({
          queryKey: ["checkUnclaimed"],
        });
        break;
      case EventDetail.REFERRAL_WINNER:
        queryClient.invalidateQueries({
          queryKey: ["checkUnclaimed"],
        });
        queryClient.invalidateQueries({
          queryKey: ["winningReferrals"],
        });
        queryClient.invalidateQueries({
          queryKey: ["referrals"],
        });
        queryClient.invalidateQueries({
          queryKey: ["referralAnalytics"],
        });
        break;
      case EventDetail.REFERRAL_UPDATE:
        queryClient.invalidateQueries({
          queryKey: ["winningReferrals"],
        });
        queryClient.invalidateQueries({
          queryKey: ["referrals"],
        });
        queryClient.invalidateQueries({
          queryKey: ["referralAnalytics"],
        });
        break;
      case EventDetail.REFERRAL_UNLOCKED:
        toast({
          title: t("toast.unlockedReferral"),
          description: (
            <div className={"flex items-center gap-4"}>
              <div
                className={
                  "flex items-center justify-center w-[38px] h-[38px] rounded-[50%] bg-[#26616C]"
                }
              >
                <img src={giftSvg} alt="gift" />
              </div>
              <div>{t("toast.findInSection")}</div>
            </div>
          ),
          variant: "reminder",
          icon: (
            <div
              className={
                "flex items-center justify-center w-[70px] h-[70px] rounded-[50%] bg-[#07252A]"
              }
            >
              <img src={myReferral} alt="gift" />
            </div>
          ),
        });
    }
  }, [setHasUnreadNotifications, queryClient, lastJsonMessage, user?.id]);

  const [showLoader, setShowLoader] = useState(false);

  const loadingInProgress =
    languagesLoading || roundsLoading || currenciesLoading || isLoadingUser;

  useEffect(() => {
    if (!loadingInProgress) {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [loadingInProgress]);

  return (
    <div
      className={
        "bg-background h-full w-screen flex flex-col items-center overflow-hidden relative"
      }
    >
      <Header openMobileMenu={() => setIsMobileMenuOpen(true)} />
      {isMobileMenuOpen && (
        <MobileMenu closeMenu={() => setIsMobileMenuOpen(false)} />
      )}
      {showLoader ? <StealdealLoader /> : <Outlet />}
      {user && (
        <div
          onClick={() => setIsChatOpen((state) => !state)}
          className="bg-gradientGold pl-4 pr-4 pt-[4px] pb-[4px] flex items-center justify-center rotate-[270deg] text-black cursor-pointer absolute bottom-[20%] rounded-t-lg right-[-32px] z-[60]"
        >
          <span>{t("chat.liveChat")}</span>
        </div>
      )}
      {isChatOpen && (
        <ControlledForm schema={chatMessageSchema}>
          <Chat onClose={() => setIsChatOpen((state) => !state)} />
        </ControlledForm>
      )}
      {checkUnclaimed && (
        <ControlledForm schema={sendMessageSchema}>
          <WinnerComment
            isOpen={isOpen}
            onClose={toggleClaimDialog}
            unclaimedDealResponse={checkUnclaimed}
          />
        </ControlledForm>
      )}
      <SideMenu />
      <Toaster />
      <NotificationPopup />
      <ChangeLanguageDialog
        isOpen={isLanguageDialogOpen}
        onClose={() => setIsLanguageDialogOpen(false)}
      />
      <AddReferralDialog
        isOpen={isAddReferralDialogOpen}
        onClose={() => setIsAddReferralDialogOpen(false)}
      />
      <ControlledForm schema={uploadImageSchema}>
        <UploadImageDialog
          isOpen={isUploadImageDialogOpen}
          onClose={() => setIsUploadImageDialogOpen(false)}
        />
      </ControlledForm>
      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        onClose={() => setIsChangePasswordDialogOpen(false)}
      />
      <CompleteSignUpDialog
        isOpen={isIncompleteOpen}
        onClose={toggleIsIncompleteDialog}
      />
      <LockScreenDialog
        isOpen={isLockScreenDialogOpen}
        onClose={() => setIsLockScreenDialogOpen(false)}
        onSuccess={() => {
          setIsSuccessSetDialogOpen(true);
          setIsLockScreenDialogOpen(false);
        }}
      />
      <ChangePinDialog
        isOpen={isChangePinDialogOpen}
        onClose={() => setIsChangePinDialogOpen(false)}
        action={() => {
          setIsChangePinDialogOpen(false);
          setIsNewPinDialogOpen(true);
        }}
      />
      <NewPinDialog
        isOpen={isNewPinDialogOpen}
        onClose={() => setIsNewPinDialogOpen(false)}
        onSuccess={() => {
          setIsSuccessDialogOpen(true);
          setIsNewPinDialogOpen(false);
        }}
      />
      <PinChangeSuccessDialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
      />
      <PinSetSuccessDialog
        isOpen={isSuccessSetDialogOpen}
        onClose={() => setIsSuccessSetDialogOpen(false)}
        openChangePinDialog={() => setIsNewPinDialogOpen(true)}
      />
    </div>
  );
};

export default MainLayout;
