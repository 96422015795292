import { useTranslation } from "react-i18next";

import logo from "/svg/logo-icon-steal-deal.png";
import arrowUp from "/svg/arrow-up.svg";
import discord from "/svg/discord.svg";
import x from "/svg/x.svg";
import telegram from "/svg/telegram.svg";
import youtube from "/svg/youtube.svg";
import fb from "/svg/fb.svg";
import instagram from "/svg/instagram.svg";
import medium from "/svg/medium.svg";

export const AboutUs = () => {
  const { t } = useTranslation();
  const socials = [
    {
      icon: telegram,
      link: "https://t.me/astealdeal",
    },
    {
      icon: x,
      link: "https://x.com/ASteal_Deal",
    },
    {
      icon: discord,
      link: "https://discord.com/users/1255509782706520098",
    },
    {
      icon: youtube,
      link: "https://www.youtube.com/@AStealDeal",
    },
    {
      icon: fb,
      link: "https://www.facebook.com/people/A-Steal-Deal/pfbid02DnitUpsahqKqX7SYZhHZFHTm2BZtJyRrqHT4QMjtAPmRw6ivWUPZSgn6ARJx9JxTl/",
    },
    {
      icon: instagram,
      link: "https://www.instagram.com/a_steal_deal/",
    },
    {
      icon: medium,
      link: "https://medium.com/@media_94277",
    },
  ];

  return (
    <div className="flex flex-col w-full h-full pt-[5rem]">
      <div className="flex flex-col h-full w-full items-center xl:pt-[0rem] sm:pt-[4rem] pt-[3rem] justify-between">
        <div className="h-full flex flex-col items-center justify-around">
          <div className="flex flex-col items-center gap-[0.5rem]">
            <img
              src={logo}
              alt={"logo"}
              className="w-[3.125rem] h-[3.125rem]"
            />
            <span className="avenir-semibold text-white text-[24px] text-center">
              {t("common.stealDeal")}
            </span>
            <span className="text-[16px] text-white text-center">{`${t("common.version")} 4.43.5`}</span>
          </div>
          <div
            className={"flex flex-row cursor-pointer gap-[0.5rem] items-center"}
            onClick={() => window.open("https://www.astealdeal.com")}
          >
            <span className="text-[16px] text-white">
              {"www.astealdeal.com"}
            </span>
            <img
              src={arrowUp}
              alt={"arrow-up"}
              className="w-[0.75rem] h-[1rem]"
            />
          </div>
          <div className={"flex flex-col items-center gap-[1.375rem]"}>
            <span className="text-textGray text-[18px]">
              {t("common.followUs")}
            </span>
            <div
              className={
                "flex flex-row flex-wrap justify-center gap-[1.375rem]"
              }
            >
              {socials.map((social) => (
                <img
                  src={social.icon}
                  onClick={() => window.open(social.link)}
                  className={"h-[1.875rem] w-[1.875rem] cursor-pointer"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
